import { DEFAULT_LANGUAGE_CODE, LANGUAGE_COOKIE } from "@nf/constants";
import Script from "next/script";
import { getCookie } from "cookies-next";
import { useEffect, useState } from "react";
import { GoogleTagManager } from "../GoogleTagManager";
import { NewRelicMonitoring } from "../NewRelicMonitoring";

const COOKIE_CONSENT_ID = "CookieConsent";
const IS_PROD_ENV = process.env.NEXT_PUBLIC_API_BUSINESS_UNIT === "NFProd";

export const CookieConsent = () => {
  const [cookieConsentLoaded, setCookieConsentLoaded] = useState(false);
  let dataCulture = DEFAULT_LANGUAGE_CODE.toUpperCase();

  try {
    const langCookie = getCookie(LANGUAGE_COOKIE) as string | undefined;

    if (langCookie && langCookie?.length > 0) {
      dataCulture = langCookie?.toUpperCase();
    }
  } catch (error) {
    console.error("Error getting lang cookie", error);
  }

  useEffect(() => {
    const dataCultureAttr = document
      .getElementById(COOKIE_CONSENT_ID)
      ?.getAttribute("data-culture");

    if (dataCultureAttr !== dataCulture) {
      document
        .getElementById(COOKIE_CONSENT_ID)
        ?.setAttribute("data-culture", dataCulture);
    }
  }, [dataCulture]);

  const onScriptLoad = () => {
    setCookieConsentLoaded(true);
  };

  return (
    <>
      <Script
        id={COOKIE_CONSENT_ID}
        src="https://policy.app.cookieinformation.com/uc.js"
        data-consent-src="https://policy.app.cookieinformation.com/uc.js"
        data-culture={dataCulture}
        data-gcm-version="2.0"
        onLoad={onScriptLoad}
        type="text/javascript"
        strategy="lazyOnload"
      />
      {cookieConsentLoaded && IS_PROD_ENV && <GoogleTagManager />}
      {cookieConsentLoaded && <NewRelicMonitoring />}
    </>
  );
};

export default CookieConsent;
