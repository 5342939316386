import { isServerSideRender } from "@nf/helpers";
import { filterPeopleByRole, IMediaModel, PersonInAssetType } from "@nf/common";
import { CANCELLATION_REASONS, PRIMARY_REASONS } from "@nf/constants";

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

type GAEvent = {
  email?: string;
  event: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  eventValue?: string;
  nonInteraction?: boolean;
  [key: string]: any;
};

type Translations = {
  [key: string]: string;
};

type SubReason =
  | {
      Id: string;
      translationKey: string;
    }
  | undefined;

const commonGAEvent: GAEvent = {
  email: "",
  event: "genericGAEvent",
  eventCategory: "",
  eventAction: "",
  eventLabel: "",
  eventValue: "",
  nonInteraction: false,
};

const pushToDataLayer = (gaEvent: GAEvent) => {
  if (!isServerSideRender) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(gaEvent);
  }
};

const login = () => {
  pushToDataLayer({
    ...commonGAEvent,
    eventCategory: "login",
  });
};

const signUp = (email: string) => {
  pushToDataLayer({
    ...commonGAEvent,
    email,
    eventCategory: "sign_up",
    eventAction: "sign_up started",
  });
};

const subscriptionCompleted = (email: string) => {
  pushToDataLayer({
    ...commonGAEvent,
    email: email,
    eventCategory: "subscription",
    eventAction: "completed",
    eventValue: "49",
  });
};

const trackMovieEnter = (media: IMediaModel) => {
  try {
    const instructor = filterPeopleByRole(
      media.People,
      PersonInAssetType.Director
    )
      ?.map((directors) => directors.PersonFullName)
      .join(", ");

    const subTitles = media?.Subtitles?.map((subtitles) => subtitles).join(",");

    const category = media.Categories?.filter(
      (category) => category.CategoryTypeCode === "MAIN"
    )
      .map((category) => category.CategoryName)
      .filter((categoryName) => categoryName.length > 0)
      .join(",");

    const ageRestriction = media.MediaAgeRestrictionValueMin
      ? parseInt(media.MediaAgeRestrictionValueMin)
      : 0;

    const genre =
      media.Categories?.filter(
        (category) => category.CategoryTypeCode === "MAIN"
      )
        .map((category) => category.CategoryName?.toUpperCase())
        .join(",") ?? "";

    const format =
      media.Categories?.filter(
        (category) => category.CategoryTypeCode === "FORMAT"
      )
        .map((category) => category.CategoryName)
        .join(",") ?? "";

    const actors = filterPeopleByRole(media.People, PersonInAssetType.Actor)
      ?.map((people) => people.PersonFullName)
      .join(",");

    const languages = media?.Languages?.map((language) => language.Name).join(
      ","
    );

    const movieInformation = {
      originaltitel: media.Title,
      instruktor: instructor,
      medvirkende: actors,
      undertekster: subTitles,
      kategori: category,
      genre,
      aldersgraense: ageRestriction,
      format,
      sprog: languages,
      year: media.Year,
    };

    const gaEvent: GAEvent = {
      event: "movie_information",
      ecommerce: movieInformation,
    };
    pushToDataLayer(gaEvent);
  } catch (error) {
    console.log("trackMovieEnter error", error);
  }
};

const cancelSubscription = (
  cancellationAnswers: string[],
  translations: Translations
) => {
  const getTranslationValue = (key: string) => {
    if (translations[key]) {
      return translations[key].toLowerCase();
    }
    return ``;
  };

  const filteredPrimaryReason: string[] = [];
  const filteredSecondaryReason: string[] = [];

  cancellationAnswers.forEach((el) => {
    if (PRIMARY_REASONS.includes(el)) {
      const item = CANCELLATION_REASONS.find((element) => element.Id === el);
      filteredPrimaryReason.push(
        getTranslationValue(item?.translationKey || "")
      );
    } else {
      let subReason: SubReason;
      for (let i = 0; i < CANCELLATION_REASONS.length; i++) {
        const subReasonItem = CANCELLATION_REASONS[i].subReasons.find(
          (subReason) => subReason.Id === el
        );
        if (subReasonItem) {
          subReason = subReasonItem;
          break;
        }
      }
      filteredSecondaryReason.push(
        getTranslationValue(subReason?.translationKey || "")
      );
    }
  });

  const GAEvent = {
    event: "subscription_cancellation_completed",
    primary_reason: filteredPrimaryReason.join(", "),
    secondary_reason: filteredSecondaryReason.join(", "),
  };

  pushToDataLayer(GAEvent);
};

export const googleAnalyticsEvent = {
  login,
  signUp,
  subscriptionCompleted,
  trackMovieEnter,
  cancelSubscription,
};
