import { ROUTES } from "@nf/constants";
import { useEffect, useState } from "react";
import Script from "next/script";
import { useRouter } from "next/router";
import { GTM_ID } from "../../lib/gtm";

export const GoogleTagManager = () => {
  const router = useRouter();

  // There is reload on payment page so delaying
  // script loading causes some events not to be sent
  const loadScriptOnStart =
    router.asPath.includes(ROUTES.PAYMENT) ||
    router.asPath.includes(ROUTES.ASSIGNING_SUBSCRIPTION_SCREEN);
  const [loaded, setLoaded] = useState(loadScriptOnStart);

  // Load script after some time so that lighthouse does not pick it up
  useEffect(() => {
    const timeout = window.setTimeout(() => setLoaded(true), 3000);
    return () => {
      window.clearTimeout(timeout);
    };
  }, []);

  if (!loaded) {
    return null;
  }

  // nosemgrep
  return (
    <Script
      id="gtm"
      data-cookieconsent="ignore"
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer', '${GTM_ID}');
            `,
      }}
    />
  );
};
